/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    code: "code",
    h2: "h2",
    pre: "pre",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "min-height",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#min-height",
    "aria-label": "min height permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Min-Height"), "\n", React.createElement(_components.p, null, "Utilities for setting the minimum height of an element."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "React props"), React.createElement(_components.th, null, "CSS Properties"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "minHeight={size}")), React.createElement(_components.td, null, React.createElement(_components.code, null, "min-height: {size};"))), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "minH={size}")), React.createElement(_components.td, null, React.createElement(_components.code, null, "min-height: {size};"))))), "\n", React.createElement(_components.h2, {
    id: "scaled-min-height",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#scaled-min-height",
    "aria-label": "scaled min height permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Scaled Min-Height"), "\n", React.createElement(_components.p, null, "All values specified in the ", React.createElement(_components.code, null, "sizes"), " theme section are automatically applied. Note there is a little difference for scales between ", React.createElement(_components.code, null, "0"), " and ", React.createElement(_components.code, null, "1"), " due to conflicting with fluid range. You have to add a suffix ", React.createElement(_components.code, null, "s"), " to target values from theme, like ", React.createElement(_components.code, null, "0.5"), " becomes ", React.createElement(_components.code, null, "0.5s"), " and ", React.createElement(_components.code, null, "1"), " becomes ", React.createElement(_components.code, null, "1s"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=light-blue"
  }, "<>\n  <template preview>\n    <x.div\n      display=\"flex\"\n      justifyContent=\"space-around\"\n      alignItems=\"flex-end\"\n      mx=\"auto\"\n    >\n      {['1s', 8, 12, 16, 24].map((v) => (\n        <x.div\n          key={v}\n          spaceY={4}\n          display=\"flex\"\n          flexDirection=\"column\"\n          alignItems=\"center\"\n        >\n          <x.div\n            minHeight={v}\n            w={8}\n            borderRadius=\"md\"\n            bg=\"light-blue-500\"\n            color=\"white\"\n            fontSize=\"2xl\"\n            fontWeight=\"extrabold\"\n          />\n          <x.p fontSize=\"sm\" color=\"light-blue-600\" textAlign=\"center\">\n            minHeight={v}\n          </x.p>\n        </x.div>\n      ))}\n    </x.div>\n  </template>\n  <x.div>\n    <x.div minHeight=\"1s\" h={0} />\n    <x.div minHeight={8} h={0} />\n    <x.div minHeight={12} h={0} />\n    <x.div minHeight={16} h={0} />\n    <x.div minHeight={24} h={0} />\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "fixed-min-height",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#fixed-min-height",
    "aria-label": "fixed min height permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Fixed Min-Height"), "\n", React.createElement(_components.p, null, "Any valid value is accepted in width, numbers are converted to ", React.createElement(_components.code, null, "px"), ", other units have to be specified."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=emerald"
  }, "<>\n  <template preview>\n    <x.div\n      display=\"flex\"\n      justifyContent=\"space-around\"\n      alignItems=\"flex-end\"\n      mx=\"auto\"\n    >\n      {[123, '12px', '4rem', '3ex'].map((v) => (\n        <x.div\n          key={v}\n          spaceY={4}\n          display=\"flex\"\n          flexDirection=\"column\"\n          alignItems=\"center\"\n        >\n          <x.div\n            w={8}\n            minHeight={v}\n            borderRadius=\"md\"\n            bg=\"emerald-500\"\n            color=\"white\"\n            fontSize=\"2xl\"\n            fontWeight=\"extrabold\"\n          />\n          <x.p fontSize=\"sm\" color=\"emerald-600\" textAlign=\"center\">\n            minHeight={v}\n          </x.p>\n        </x.div>\n      ))}\n    </x.div>\n  </template>\n  <x.div>\n    <x.div minHeight={123} h={0} />\n    <x.div minHeight=\"12px\" h={0} />\n    <x.div minHeight=\"4rem\" h={0} />\n    <x.div minHeight=\"3ex\" h={0} />\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "fluid-min-height",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#fluid-min-height",
    "aria-label": "fluid min height permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Fluid Min-Height"), "\n", React.createElement(_components.p, null, "Values from ", React.createElement(_components.code, null, "0"), " to ", React.createElement(_components.code, null, "1"), " are converted into percentages. As a fraction or a number, both are an expression. Of course specifying ", React.createElement(_components.code, null, "[value]%"), " is also possible."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=rose"
  }, "<>\n  <template preview>\n    <x.div\n      display=\"flex\"\n      justifyContent=\"space-around\"\n      alignItems=\"flex-end\"\n      mx=\"auto\"\n    >\n      {[\n        [['1/2', 0.5], 0.1],\n        [['0.5', 0.5], 0.7],\n      ].map((v, i) => (\n        <x.div\n          key={i}\n          overflow=\"hidden\"\n          borderRadius=\"md\"\n          color=\"white\"\n          fontFamily=\"mono\"\n          h={48}\n          display=\"flex\"\n          flexDirection=\"column\"\n          justifyContent=\"flex-end\"\n          bg=\"cool-gray-300\"\n        >\n          {v[1] > v[0][1] && (\n            <x.div\n              h={v[1] - v[0][1]}\n              px={2}\n              py={4}\n              bg=\"rose-500\"\n              display=\"flex\"\n              alignItems=\"center\"\n              justifyContent=\"center\"\n            >\n              {`0.2`}\n            </x.div>\n          )}\n          <x.div\n            h={v[0][1]}\n            px={2}\n            py={4}\n            bg=\"rose-400\"\n            display=\"flex\"\n            alignItems=\"center\"\n            justifyContent=\"center\"\n          >\n            minHeight={v[0][0]}\n          </x.div>\n        </x.div>\n      ))}\n    </x.div>\n  </template>\n  <x.div minHeight={1 / 2} w={0.1}>\n    minHeight=1/2\n  </x.div>\n  <x.div minHeight={0.5} w={0.7}>\n    minHeight=0.5\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "responsive",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#responsive",
    "aria-label": "responsive permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Responsive"), "\n", React.createElement(_components.p, null, "To control the margin of an element at a specific breakpoint, use responsive object notation. For example, adding the property ", React.createElement(_components.code, null, "minHeight={{ md: 1 }}"), " to an element would apply the ", React.createElement(_components.code, null, "minHeight={1}"), " utility at medium screen sizes and above."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<x.div minHeight={{ xs: 1 / 2, md: 1 }} />\n")), "\n", React.createElement(_components.p, null, "For more information about xstyled's responsive design features, check out ", React.createElement(_components.a, {
    href: "/docs/responsive-design/"
  }, "Responsive Design"), " documentation."), "\n", React.createElement(_components.h2, {
    id: "customizing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#customizing",
    "aria-label": "customizing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Customizing"), "\n", React.createElement(_components.h3, {
    id: "sizes-scale",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#sizes-scale",
    "aria-label": "sizes scale permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Sizes scale"), "\n", React.createElement(_components.p, null, "f you'd like to customize your values for width, height, min-width, min-height, max-width, max-height, all at once, use the ", React.createElement(_components.code, null, "theme.sizes"), " section of your theme."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-diffjs"
  }, "  // theme.js\n  export const theme = {\n    sizes: {\n+     sm: '8px',\n+     md: '16px',\n+     lg: '24px',\n+     xl: '48px',\n    },\n  }\n")), "\n", React.createElement(_components.p, null, "Learn more about customizing the default theme in the ", React.createElement(_components.a, {
    href: "/docs/theme/"
  }, "theme customization documentation"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
